import React, { useEffect, useState } from "react";
import info from "../../../images/InfoBg.svg";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import SelectComponent from "../../../ui/Select";
import {
  AdditionalInfo,
  ClassStarts,
  DateTime,
  Day,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import {
  ClassInfo,
  ClassName,
  // PaymentContainer,
  PaymentInfo,
  ProfessorName,
  SpanGreen,
  TimeInfo,
  Total,
  TotalPrice,
} from "../../MultiStepsForm/individual/StepSix/StepSixElements";
import { Title } from "./OverlayElements";
import styled from "styled-components";

const PaymentContainer = styled.div`
  width: 32rem;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function ConfirmPayment({ levelId, text, method, userData, handleSubmit }) {
  console.log("userData", userData);

  const [disabled, setDisabled] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");

  const amountInDollars_invoiced = userData.next_payment_value / 100;

  const formattedAmount_invoiced = (
    amountInDollars_invoiced / 1000
  ).toLocaleString("en-US", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  const lessonInfo = userData.scheduled_times?.map(
    (scheduleItem, scheduleIndex) => {
      const startTimeParts = scheduleItem.start_time.split(":");
      const startTime = startTimeParts[0] + ":" + startTimeParts[1];
      return (
        <Day key={scheduleIndex}>
          {scheduleItem.dow
            ? scheduleItem.dow.charAt(0).toUpperCase() +
              scheduleItem.dow.slice(1).toLowerCase()
            : ""}{" "}
          de cada semana - {startTime}
        </Day>
      );
    }
  );

  function getNextDateWithDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    console.log("targetDayIndex", targetDayIndex);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }

  // const nextClassDates = userData.scheduled_times?.map(
  //   (scheduleItem, scheduleIndex) => {
  //     const nextDate = getNextDateWithDay(scheduleItem.dow);
  //     return nextDate.toLocaleDateString("es-ES", {
  //       day: "numeric",
  //       month: "long",
  //       year: "numeric",
  //     });
  //   }
  // );
  const nextClassDates = userData.scheduled_times?.reduce(
    (earliestDate, scheduleItem) => {
      const nextDate = getNextDateWithDay(scheduleItem.dow);
      if (!earliestDate || nextDate < earliestDate) {
        earliestDate = nextDate;
      }
      return earliestDate;
    },
    null
  );

  const earlierDate = nextClassDates
    ? nextClassDates.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : null;

  const disableBtn = () => {
    if (
      userData?.payment_count === userData?.total_payments ||
      !selectedMethod
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  useEffect(() => {
    disableBtn();
  }, [userData, selectedMethod]);

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const nextPaymentCount = Math.min(
    userData?.payment_count + 1,
    userData?.total_payments
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <p>{levelId}</p> */}
        <img src={info} alt="" />
        <Title style={{ marginTop: "1.5rem" }}>
          Confirmación de renovación
        </Title>
        <p>{text}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {method && (
          <div style={{ marginTop: "1.5rem" }}>
            <SelectComponent
              label="Método de pago"
              menuItems={[
                { label: "Efectivo", value: "efectivo" },
                { label: "Transferencia", value: "transferencia" },
                { label: "Tarjeta debito", value: "debito" },
                { label: "Tarjeta Crédito", value: "crédito" },
              ]}
              value={selectedMethod}
              onChange={handleMethodChange}
            />
          </div>
        )}
        <div style={{ marginTop: "1.5rem", marginBottom: "3rem" }}>
          <PaymentContainer style={{ width: "32rem" }}>
            <PaymentInfo style={{ background: "none" }}>
              <ClassInfo>
                <ClassName>
                  Clases de{" "}
                  {userData.instrument?.name
                    ? userData.instrument?.name.charAt(0).toUpperCase() +
                      userData.instrument?.name.slice(1).toLowerCase()
                    : ""}
                </ClassName>
                <ProfessorName>Profesor(a) {userData.teacher}</ProfessorName>
              </ClassInfo>
              <TimeInfo>
                <Day>Intensidad {userData.intensity}</Day>
                <Day>Clases Personalizada</Day>
                {lessonInfo}
                <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                <ClassStarts>
                  Comienzo de clases<br></br>
                  {earlierDate}
                  <DateTime></DateTime>
                </ClassStarts>
              </TimeInfo>
              <Day style={{ marginBottom: "2rem" }}>
                {userData?.payment_plan === "SEMESTRAL"
                  ? "Único pago"
                  : userData?.payment_plan === "CICLO"
                  ? "Pagas cada 28 días"
                  : userData?.payment_plan
                  ? "Pagas 2 cuotas"
                  : ""}
              </Day>
              <div>
                <Day style={{ marginBottom: "1rem" }}>
                  Pago cuota ({nextPaymentCount}/{userData?.total_payments})
                </Day>
                <Total>
                  <SpanGreen>TOTAL A PAGAR</SpanGreen>
                  <TotalPrice>$ {formattedAmount_invoiced} COP</TotalPrice>
                </Total>
              </div>
            </PaymentInfo>
          </PaymentContainer>
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <AdminButtonContainer>
            {method ? (
              <AdminFormButton onClick={handleSubmit} disabled={disabled}>
                Confirmar
              </AdminFormButton>
            ) : (
              <AdminFormButton onClick={handleSubmit}>
                Confirmar
              </AdminFormButton>
            )}
          </AdminButtonContainer>
        </div>
      </div>
    </>
  );
}

export default ConfirmPayment;
