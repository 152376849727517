import React, { useEffect, useState } from 'react'
import menu from '../../../images/MobileMenu.svg'
import { Logo, Menu, Nav, NavBtn, NavItem, NavLogo, NavMenu, NavbarContainer } from '../../Navbar/NavbarElements'
import MenuProf from './MenuProf'

const Navbar = ({ loggedIn, onLogout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [close, setClose] = useState(false)

    const showSidebar = () => {
        setIsOpen(!isOpen)
    }; 

    const handleClose = () => {
        setClose(!close)
        setIsOpen(false)
    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

  return (
    <>
        <Nav>
            <NavbarContainer>
                <NavLogo to='/home'>
                    <Logo src='./logo_desktop.svg'/>
                </NavLogo>
                <NavMenu>
                    {/* <NavItem>
                        <NavLink to='/notifications-prof'><Notifications src={notifications}/></NavLink>
                    </NavItem> */}
                    <NavItem>
                        <NavBtn onClick={showSidebar}><Menu src={menu}/></NavBtn>
                    </NavItem>
                </NavMenu>
                {isOpen && <Overlay onClick={showSidebar} />}
            </NavbarContainer>
        </Nav>
                {isOpen && 
                <MenuProf isOpen={isOpen} onClose={handleClose} loggedIn={loggedIn} onLogout={onLogout} />
                }
    </>
  )
}
const Overlay = ({ onClick }) => {
    return (
        <div style={{
            position: 'fixed',
            zIndex: 999,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)', 
        }} onClick={onClick} />
    );
}

export default Navbar