import React, { useEffect, useState } from "react";
import {
  UpperWrapper,
  MainContent,
  UserInfo,
  UserName,
  UserStatus,
  CoursesInfo,
  CoursesItem,
  CoursesNum,
  CoursesDesc,
  BottomWrapper,
  ClassTitle,
  BottomContainer,
  CardsWrapper,
} from "../../MainSection/InactiveMainElements";
import NoClassesProfCard from "../../ClassesCards/NoClassesProfCard";
import PageContainer from "../../../ui/PageContainer";
import useUserName from "../../../utils/useUserName";
import styled from "styled-components";
import axios from "axios";

const ClassWrapper = styled.div`
  background-color: ${(props) => {
    if (props.status === "PENDING" || props.status === "ACTIVE")
      return "#E8F6EE";
    if (props.status === "COMPLETED") return "#F3F3F3";
  }};
  height: 7.46rem;
  width: 32.8rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 29rem;
    height: 8.5rem;
  }
  @media screen and (max-width: 480px) {
    width: 24rem;
    height: 8.5rem;
  }
`;

const ClassInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.06rem 1rem 1rem 1rem;
`;

const Left = styled.div`
  /* Add styles for Left */
`;

const DateDay = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.06rem;
`;

const ClassType = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
`;

const Professor = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const Place = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;

const Right = styled.div`
  font-size: 0.875rem;
  text-align: center;
`;

const Time = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.87rem;
`;

const Status = styled.p`
  background-color: ${(props) =>
    props.status === "PENDING" || props.status === "ACTIVE"
      ? "#41A46D"
      : props.status === "COMPLETED"
      ? "#ADB8BA"
      : "white"};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding: 0.12rem 0.5rem;
  border-radius: 0.75rem;
  color: #fff;
`;

const Room = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;

const MainProf = () => {
  const { name } = useUserName(localStorage.getItem("userId"));
  const [nextLesson, setNextLesson] = useState("");
  const [stats, setStats] = useState("");

  useEffect(() => {
    const fetchNextLesson = async () => {
      try {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/next_lesson`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setNextLesson(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data for the next lesson:", error);
      }
    };
    fetchNextLesson();
  }, []);

  console.log("nextLesson", nextLesson);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/stats`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setStats(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data for the next lesson:", error);
      }
    };
    fetchStats();
  }, []);

  const formatStudents = () => {
    return nextLesson?.students
      .map((student) => `${student.first_name} ${student.last_name}`)
      .join(", ");
  };

  const capitalizedInstrumentName = nextLesson?.instrument_name
    ? nextLesson?.instrument_name.charAt(0).toUpperCase() +
      nextLesson?.instrument_name.slice(1).toLowerCase()
    : "";

  const startDate = new Date(nextLesson?.start_time);
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let formattedStartDate = startDate.toLocaleDateString("es-ES", options);
  formattedStartDate = formattedStartDate.replace(
    /^(.)(.+?)\b(?! de )/g,
    (match, p1, p2) => p1.toUpperCase() + p2
  );
  formattedStartDate = formattedStartDate.replace(/,/g, "");
  const formattedStartTime = startDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  let modality = nextLesson?.lesson_type;
  if (nextLesson?.lesson_type === "PRESENCIAL") {
    modality = "Presencial en sede";
  } else if (nextLesson?.lesson_type === "VIRTUAL") {
    modality = "Virtual";
  } else if (nextLesson?.lesson_type === "DOMICILIO") {
    modality = "Domicilio";
  }

  return (
    <PageContainer>
      <MainContent>
        <UpperWrapper>
          <UserInfo>
            <UserName>Hola, {name.first_name}</UserName>
            <UserStatus style={{ background: "#41A46D", color: "#fff" }}>
              Profesor activo
            </UserStatus>
          </UserInfo>
          <CoursesInfo>
            <CoursesItem>
              <CoursesNum>{stats.individual_levels}</CoursesNum>
              <CoursesDesc>Cursos Individuales Disponibles</CoursesDesc>
            </CoursesItem>
            <CoursesItem>
              <CoursesNum>{stats.groupal_levels}</CoursesNum>
              <CoursesDesc>Cursos Grupales Disponibles</CoursesDesc>
            </CoursesItem>
            <CoursesItem>
              <CoursesNum>{stats.cancelled_lessons}</CoursesNum>
              <CoursesDesc>Clases canceladas del ciclo</CoursesDesc>
            </CoursesItem>
          </CoursesInfo>
        </UpperWrapper>
        <BottomWrapper>
          <ClassTitle>Próxima clase</ClassTitle>
          <BottomContainer>
            <CardsWrapper>
              {nextLesson ? (
                <ClassWrapper status={nextLesson.status}>
                  <ClassInformation>
                    <Left>
                      <DateDay>{formattedStartDate}</DateDay>
                      <ClassType>
                        Clase de {capitalizedInstrumentName}
                      </ClassType>
                      <Professor>Estudiantes: {formatStudents()}</Professor>
                      <Place>{modality}</Place>
                    </Left>
                    <Right>
                      <Time>{formattedStartTime}</Time>
                      <Status status={nextLesson.status}>
                        {nextLesson.status === "PENDING"
                          ? "Pendiente"
                          : nextLesson.status === "ACTIVE"
                          ? "Activa"
                          : nextLesson.status === "COMPLETED"
                          ? "Terminado"
                          : nextLesson.status === "CANCELLED"
                          ? "Cancelada"
                          : ""}
                      </Status>
                      <Room>{nextLesson.classroom_name}</Room>
                    </Right>
                  </ClassInformation>
                </ClassWrapper>
              ) : (
                <NoClassesProfCard />
              )}
            </CardsWrapper>
          </BottomContainer>
        </BottomWrapper>
      </MainContent>
    </PageContainer>
  );
};

export default MainProf;
