import React from "react";
import AdminPageContainer from "../../../ui/AdminPageContainer";
import { MainContent } from "../../MainSection/InactiveMainElements";
import {
  BtnContainer,
  BtnLink,
  BtnSale,
  BtnWrapper,
  Title,
} from "../CreateUser/CreateUserElements";

function SaleGroupClass({ defaultStep }) {
  return (
    <AdminPageContainer>
      <MainContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2.5rem",
          width: "100%",
        }}
      >
        <div>
          <Title>Vender clase grupal</Title>
        </div>
        <BtnContainer>
          <BtnWrapper>
            <BtnLink
              to="/vender-clase-grupal/paso-1"
              style={{ whiteSpace: "nowrap" }}
            >
              <BtnSale />
              Vender clase grupal
            </BtnLink>
          </BtnWrapper>
        </BtnContainer>
      </MainContent>
    </AdminPageContainer>
  );
}

export default SaleGroupClass;
