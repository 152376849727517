import React from "react";
import { useNavigate } from "react-router-dom";
import logout from "../../images/Log_Out.svg";
import support from "../../images/Circle_Help.svg";
import {
  NavbarContainer,
  NavProfile,
  ProfileImg,
  Name,
  Type,
  NavMenu,
  NavItem,
  NavBtn,
  SupportBtn,
  SupportImg,
  LogOutBtn,
  LogOutImg,
  Span,
  BottomWrapper,
  BtnInicio,
  BtnCalend,
  BtnComprar,
  Groups,
  ActiveClass,
} from "./SideNavbarElements";
import Logo from "../../ui/Logo";
import useUserName from "../../utils/useUserName";
import styled from "styled-components";
import edit from "../../images/EditProfile.svg";

export const BtnDisabled = styled.button`
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const SideNavbar = ({ loggedIn, onLogout }) => {
  const navigate = useNavigate();

  const { name } = useUserName(localStorage.getItem("userId"));

  const handleLogout = () => {
    onLogout();
    navigate("/");
  };

  return (
    <>
      <NavbarContainer>
        <Logo />
        <div>
          <NavProfile to="/edit-profile">
            <ProfileImg />
            <div>
              <Name>
                {name.first_name} {name.last_name}
              </Name>
              <Type>
                Estudiante <img src={edit} alt="" />
              </Type>
            </div>
          </NavProfile>
          <NavMenu>
            <NavItem>
              <NavBtn to="/inicio">
                <BtnInicio />
                <Span>Inicio</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/calendario">
                <BtnCalend />
                <Span>Ver calendario</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/comprar-curso-individual">
                <BtnComprar />
                <Span style={{ textAlign: "left" }}>
                  Comprar curso individual
                </Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
               <BtnDisabled disabled>
                <BtnComprar />
                <Span style={{textAlign:'left'}}>Comprar curso individual</Span>
              </BtnDisabled>
            </NavItem> */}
            <NavItem>
              <NavBtn to="/renovar-clases">
                <ActiveClass />
                <Span style={{ textAlign: "left" }}>Renovar clases</Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
              <BtnDisabled disabled>
                <ActiveClass />
                <Span style={{textAlign:'left'}}>Renovar clases</Span>
              </BtnDisabled>
            </NavItem> */}
            {/* <NavItem>
              <BtnDisabled disabled>
              <BtnComprar />
                <Span>Comprar nuevo curso</Span>
              </BtnDisabled>
            </NavItem> */}
            {/* <NavItem>
              <BtnDisabled disabled>
                <Groups />
                <Span>Solicitar clase grupal</Span>
              </BtnDisabled>
            </NavItem> */}
            <NavItem>
              <NavBtn to="/solicitar-clase-grupal">
                <Groups />
                <Span>Solicitar clase grupal</Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
              <NavBtn to="/notifications">
                <BtnNotif />
                <Span>Notificaciones</Span>
              </NavBtn>
            </NavItem> */}
          </NavMenu>
        </div>
        <BottomWrapper>
          <SupportBtn>
            <SupportImg src={support} />
            ¿Necesitas contactar a soporte? ¡Házlo aquí!
          </SupportBtn>
          {loggedIn && (
            <LogOutBtn onClick={handleLogout}>
              <LogOutImg src={logout} />
              Cerrar sesión
            </LogOutBtn>
          )}
        </BottomWrapper>
      </NavbarContainer>
    </>
  );
};

export default SideNavbar;
