import React, { useEffect, useState } from "react";
import {
  AdditionalPrice,
  ClassInfo,
  ClassName,
  CyclePrice,
  PaymentContainer,
  PaymentInfo,
  PaymentWrapper,
  Price,
  ProfessorName,
  Span1,
  SpanGreen,
  TimeInfo,
  Total,
  TotalPrice,
} from "../../MultiStepsForm/individual/StepSix/StepSixElements";
import {
  AdditionalInfo,
  ClassStarts,
  DateTime,
  Day,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import SelectComponent from "../../../ui/Select";
import axios from "axios";

function StepFour({ formData, onDataChange }) {
  console.log(formData);
  const [prices, setPrices] = useState({});

  let closestStartDate = formData.lesson;

  useEffect(() => {
    const id = localStorage.getItem("userId");
    const url = `${process.env.REACT_APP_API_URL}/lesson/pricing?pricing_model=${formData.pricing_model}&lessons_per_week=${formData.intensity}&lesson_type=${formData.lesson_type}&user_id=${id}&is_groupal=false&is_dance=false&discount=0`;
    console.log(url);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Prices", response.data);
        setPrices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pricing data:", error);
      });
  }, [formData.lesson_type, formData.intensity, formData.pricing_model]);

  if (formData.lessons.length > 1) {
    closestStartDate = formData.lessons.reduce((closest, current) => {
      return Math.abs(new Date(current.start_date) - new Date()) <
        Math.abs(new Date(closest.start_date) - new Date())
        ? current
        : closest;
    });
  } else if (formData.lessons.length === 1) {
    closestStartDate = formData.lessons[0];
  }

  const lessonInfo = formData.lessons.map((lessonItem, index) => {
    const startTimeParts = lessonItem.start_time.split(":");
    const startTime = startTimeParts[0] + ":" + startTimeParts[1];
    return (
      <Day key={index}>
        {lessonItem.day.charAt(0).toUpperCase() +
          lessonItem.day.slice(1).toLowerCase()}{" "}
        de cada semana - {startTime}
      </Day>
    );
  });

  let volumeOptions = [];
  if (formData.intensity === "1") {
    volumeOptions = [
      { label: "1 semestre (20 clases)", value: "semestre" },
      { label: "2 trimestres (24 clases)", value: "trimestre" },
    ];
  } else if (formData.intensity === "2") {
    volumeOptions = [
      { label: "1 semestre (40 clases)", value: "semestre" },
      { label: "2 trimestres (48 clases)", value: "trimestre" },
    ];
  }

  const paymentOptions =
    formData.volume === "semestre"
      ? [
        { label: "Contado", value: "SEMESTRAL" },
        { label: "5 cuotas", value: "CICLO" },
      ]
      : [{ label: "2 cuotas", value: "TRIMESTRAL" }];

  const paymentDayOptions = [
    { label: "1", value: 1 },
    { label: "5", value: 5 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
  ];

  // Función que envía inmediatamente el día de pago al backend
  const updatePaymentDay = async (newPaymentDay) => {
    const payload = {
      user_id: localStorage.getItem("userId") || "12345",
      amount: 0, // Valor dummy ya que solo actualizamos paymentdate
      currency: "USD",
      transaction_id: "tx_update_paymentdate",
      metadata: {
        paymentdate: newPaymentDay,
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/paymentdata`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      console.log("Payment day updated:", response.data);
    } catch (error) {
      console.error(
        "Error updating payment day:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handlePaymentTypeChange = (fieldName) => (event) => {
    const value = event.target.value;
    let updatedValue = value;
    // Convertir a número para paymentdate y disparar actualización inmediata
    if (fieldName === "paymentdate") {
      updatedValue = parseInt(value, 10);
      updatePaymentDay(updatedValue);
    }
    const updatedFormData = { ...formData, [fieldName]: updatedValue };
    onDataChange(updatedFormData);
    console.log(updatedFormData);
  };

  // Se asume que los valores de precios vienen en centavos
  const amountValue = prices.total_value / 100;
  const amountTuition = prices.tuition / 100;
  const amountDiscount = prices.payment_discount / 100;
  const amountTotal = prices.payment / 100;

  const formatAmount = (amount) => {
    if (amount) {
      return (amount / 1000).toLocaleString("en-US", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    }
    return "";
  };

  const totalValue = formatAmount(amountValue);
  const tuitionValue = formatAmount(amountTuition);
  const paymentDiscount = formatAmount(amountDiscount);
  const total = formatAmount(amountTotal);

  return (
    <div style={{ height: "70vh", overflow: "auto" }}>
      <div
        style={{
          paddingTop: "0.2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
        }}
      >
        <SelectComponent
          label="Volumen"
          menuItems={volumeOptions}
          onChange={handlePaymentTypeChange("volume")}
          value={formData.volume}
          name="volume"
        />
        <SelectComponent
          label="Modalidad de pago"
          menuItems={paymentOptions}
          value={formData.pricing_model}
          onChange={handlePaymentTypeChange("pricing_model")}
        />
        {/* Si el modelo es CICLO se muestra el día de pago */}
        {formData.pricing_model === "CICLO" && (
          <SelectComponent
            label="Día de pago"
            menuItems={paymentDayOptions}
            value={formData.paymentdate}
            onChange={handlePaymentTypeChange("paymentdate")}
          />
        )}
        <SelectComponent
          label="Método de pago"
          menuItems={[
            { label: "Efectivo", value: "efectivo" },
            { label: "Transferencia", value: "transferencia" },
            { label: "Tarjeta débito", value: "debito" },
            { label: "Tarjeta crédito", value: "crédito" },
          ]}
          onChange={handlePaymentTypeChange("payment_method")}
          value={formData.payment_method}
        />
      </div>
      <PaymentWrapper>
        <PaymentContainer style={{ width: "39rem" }}>
          <PaymentInfo style={{ borderRadius: "0.5rem" }}>
            <ClassInfo>
              <ClassName>
                Clases de{" "}
                {formData.instrument_name.charAt(0).toUpperCase() +
                  formData.instrument_name.slice(1)}
              </ClassName>
              <ProfessorName>
                Profesor(a) {formData.teacher_name}
              </ProfessorName>
            </ClassInfo>
            <TimeInfo>
              <Day>Intensidad {formData.intensityName}</Day>
              <Day>Clases Personalizada</Day>
              <Day>Modalidad de clases {formData.lesson_type}</Day>
              {lessonInfo}
              <AdditionalInfo>Horarios Fijos</AdditionalInfo>
              <ClassStarts>
                Comienzo de clases
                <br />
                {closestStartDate && (
                  <DateTime>{closestStartDate.start_date}</DateTime>
                )}
              </ClassStarts>
            </TimeInfo>
            <Day style={{ marginBottom: "2rem" }}>
              {formData.pricing_model === "SEMESTRAL"
                ? "Único pago"
                : formData.pricing_model === "CICLO"
                  ? `Día de pago ${formData?.paymentdate} de cada mes`
                  : formData.pricing_model === "TRIMESTRAL"
                    ? "Pagas 2 cuotas"
                    : ""}
            </Day>
            <div>
              <Day style={{ marginBottom: "1rem" }}>
                {formData.pricing_model === "CICLO"
                  ? "Pago primera cuota (1/5)"
                  : formData.pricing_model === "TRIMESTRAL"
                    ? "Pago primera cuota (1/2)"
                    : ""}
              </Day>
              <CyclePrice>
                <Span1>Valor del clases</Span1>
                <Price>$ {totalValue} COP</Price>
              </CyclePrice>
              <CyclePrice>
                <Span1>Valor del descuento</Span1>
                <Price>-$ {paymentDiscount} COP</Price>
              </CyclePrice>
              {formData.tuition > 0 && (
                <AdditionalPrice>
                  <Span1>Valor matrícula (única vez)</Span1>
                  <Price>$ {tuitionValue} COP</Price>
                </AdditionalPrice>
              )}
              <Total>
                <SpanGreen>TOTAL A PAGAR</SpanGreen>
                <TotalPrice>$ {total} COP</TotalPrice>
              </Total>
            </div>
          </PaymentInfo>
        </PaymentContainer>
      </PaymentWrapper>
    </div>
  );
}

export default StepFour;
