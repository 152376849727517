import React from "react";
import { useNavigate } from "react-router-dom";
import logout from "../../../images/Log_Out.svg";
import Logo from "../../../ui/Logo";
import {
  BottomWrapper,
  BtnInicio,
  Create,
  CreateGroup,
  Estudiantes,
  Groups,
  LogOutBtn,
  LogOutImg,
  Name,
  NavBtn,
  NavItem,
  NavMenu,
  NavProfile,
  // NavbarContainer,
  Profesores,
  ProfileImg,
  Span,
  Type,
  Users,
} from "../../SideNavbar/SideNavbarElements";
import useUserName from "../../../utils/useUserName";
import styled from "styled-components";

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 19rem;
  background: #fff;
`;

function NavbarAdmin({ loggedIn, onLogout }) {
  const navigate = useNavigate();

  const { name } = useUserName(localStorage.getItem("userId"));

  const handleLogout = () => {
    onLogout();
    navigate("/");
  };

  return (
    <>
      <NavbarContainer>
        <Logo />
        <div>
          <NavProfile>
            <ProfileImg />
            <div>
              <Name>
                {name.first_name} {name.last_name}
              </Name>
              <Type>Administrador</Type>
            </div>
          </NavProfile>
          <NavMenu>
            <NavItem>
              <NavBtn to="/salones">
                <BtnInicio />
                <Span>Salones</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/estudiantes">
                <Estudiantes />
                <Span>Estudiantes</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/profesores">
                <Profesores />
                <Span>Profesores</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/crear-grupos">
                <Groups />
                <Span>Crear grupos</Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
              <BtnDisabled disabled>
                <Groups />
                <Span>Crear grupos</Span>
              </BtnDisabled>
            </NavItem> */}
            <NavItem>
              <NavBtn to="/crear-usuario">
                <Users />
                <Span>Crear usuarios</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/crear-clase-individual">
                <Create />
                <Span>Crear clase individual</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/vender-clase-grupal">
                <CreateGroup />
                <Span>Vender clase grupal</Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
                <BtnDisabled disabled>
                  <CreateGroup />
                  <Span>Vender clase grupal</Span>
                </BtnDisabled>
              </NavItem> */}
            {/* <NavItem>
              <BtnDisabled disabled>
                <Create />
                <Span>Crear clase individual</Span>
              </BtnDisabled>
            </NavItem> */}
          </NavMenu>
        </div>
        <BottomWrapper>
          {loggedIn && (
            <LogOutBtn onClick={handleLogout}>
              <LogOutImg src={logout} />
              Cerrar sesión
            </LogOutBtn>
          )}
        </BottomWrapper>
      </NavbarContainer>
    </>
  );
}

export default NavbarAdmin;
