import React from "react";
import { Icon, InfoTextContainer, Title } from "../../Overlay/OverlayElements";
import accept from "../../../images/Accept.svg";
import styled from "styled-components";

const UserInfo = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
  text-align: center;
  width: 60vh;
`;

function ClassCreated(handleSubmit) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "45vh",
        }}
      >
        <InfoTextContainer>
          <Icon src={accept} />
          <Title>Venta de clase grupal creada exitosamente</Title>
          <UserInfo>
            Hemos creado la solicitud de clase grupal de manera satisfactoria y
            ahora podrás visualizar la solicitud del estudiante en el listado de
            las solicitudes pendientes para creación de clase grupal.
          </UserInfo>
        </InfoTextContainer>
      </div>
    </>
  );
}

export default ClassCreated;
