import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ActiveCourse from "./components/ActiveCourses/ActiveCourse";
import CreatePassword from "./components/RecoverPassword/CreatePassword";
import RegistrationProf from "./components/professors/RegistrationProf/RegistrationProf";
import { BuyIndividualProvider } from "./context/BuyIndividual";
import { CreateIndividualClassProvider } from "./context/CreateIndividualClass";
import { CreateFormProvider } from "./context/CreateStudentContext";
import { FormProvider } from "./context/FormContext";
import { TeacherFormProvider } from "./context/TeacherFormContext";
import CreateClassPage from "./pages/AdminPages/createClassPage";
import CreateUserPage from "./pages/AdminPages/createUserPage";
import FormAdm from "./pages/AdminPages/formAdm";
import FormPrf from "./pages/AdminPages/formPrf";
import FormSt from "./pages/AdminPages/formSt";
import IndividualClassPage from "./pages/AdminPages/individualClassPage";
import TableProfessors from "./pages/AdminPages/tableProfessors";
import TableSalones from "./pages/AdminPages/tableSalones";
import TableStudetns from "./pages/AdminPages/tableStudetns";
import CalendarProfPage from "./pages/ProfessorPage/calendarProfPage";
import HomePageProf from "./pages/ProfessorPage/homePageProf";
import ManageProfPage from "./pages/ProfessorPage/manageProfPage";
import CalendarPage from "./pages/StudentPages/calendarPage";
import EditProfilePage from "./pages/StudentPages/editProfilePage";
import MultiStepFormPage from "./pages/StudentPages/formPage";
import HomePage from "./pages/StudentPages/home";
import LoginPage from "./pages/login";
import RecoverPasswordPage from "./pages/recoverPasswordPage";
import RegistationPage from "./pages/registation";
import SettingUpPage from "./pages/settingUpPage";
import GlobalStyles from "./styles/GlobalStyles";
import AdminLayout from "./ui/AdminLayout";
import ProfessorsLayout from "./ui/ProfessorsLayout";
import StudentsLayout from "./ui/StudentsLayout";
import { useUserAuthentication } from "./utils/useUserAuthentication";
import GroupClass from "./pages/StudentPages/groupClass";
import { StudentGroupProvider } from "./context/StudentsGroup";
import TableGroup from "./pages/AdminPages/tableGroup";
import SaleGroupClassPage from "./pages/AdminPages/saleGroupClassPage";
import SellGroupForm from "./pages/AdminPages/sellGroupForm";

function App() {
  const { handleLogin, handleLogout, loggedIn, userType } =
    useUserAuthentication();

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate replace to="/login" />} />
          <Route path="login" element={<LoginPage onLogin={handleLogin} />} />
          <Route
            path="cambio-de-contraseña"
            element={<RecoverPasswordPage />}
          />
          {/* <Route path="configurar-contraseña" element={<CreatePassword />} /> */}
          <Route path="reset_password" element={<CreatePassword />} />

          {/*----------------Registartions--------------*/}
          <Route element={<FormProvider />}>
            <Route path="registro/:paso?" element={<RegistationPage />} />
          </Route>
          <Route path="join-us" element={<RegistrationProf />} />
          <Route element={<TeacherFormProvider />}>
            <Route path="setting-up/:paso?" element={<SettingUpPage />} />
          </Route>

          {/*--------------Pages inside of the accounts------------*/}
          <>
            {/*----------Student account------------*/}
            {userType === "STUDENT" && (
              <Route
                element={
                  <StudentsLayout loggedIn={loggedIn} onLogout={handleLogout} />
                }
              >
                <Route
                  path="inicio"
                  element={
                    <HomePage loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />
                <Route
                  path="/payment"
                  element={
                    <HomePage loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />
                <Route element={<BuyIndividualProvider />}>
                  <Route
                    path="comprar-curso-individual"
                    element={
                      <MultiStepFormPage
                        loggedIn={loggedIn}
                        onLogout={handleLogout}
                      />
                    }
                  />
                </Route>
                <Route
                  path="renovar-clases"
                  element={
                    <ActiveCourse loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />
                <Route element={<StudentGroupProvider />}>
                  <Route
                    path="solicitar-clase-grupal"
                    element={
                      <GroupClass loggedIn={loggedIn} onLogout={handleLogout} />
                    }
                  />
                </Route>
                {/* <Route
                  path="notifications"
                  element={
                    <NotificationsPage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                /> */}
                <Route
                  path="calendario"
                  element={
                    <CalendarPage loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />

                <Route
                  path="edit-profile"
                  element={
                    <EditProfilePage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
              </Route>
            )}

            {/*-----------------Professor account--------------------*/}
            {userType === "TEACHER" && (
              <Route
                element={
                  <ProfessorsLayout
                    loggedIn={loggedIn}
                    onLogout={handleLogout}
                  />
                }
              >
                <Route
                  path="home-professor"
                  element={
                    <HomePageProf loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />

                {/* <Route
                  path="notifications-prof"
                  element={
                    <NotificationsProfPage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                /> */}
                <Route
                  path="calendar-prof"
                  element={
                    <CalendarProfPage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
                <Route
                  path="manage-availibility"
                  element={
                    <ManageProfPage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
              </Route>
            )}

            {/*------------Admin account--------------*/}
            {userType === "ADMIN" && (
              <Route
                element={
                  <AdminLayout loggedIn={loggedIn} onLogout={handleLogout} />
                }
              >
                <Route
                  exact
                  path="crear-usuario"
                  element={
                    <CreateUserPage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
                <Route
                  element={<CreateFormProvider initialUserType="STUDENT" />}
                >
                  <Route
                    path="crear-usuario/estudiante/:paso?"
                    element={<FormSt defaultStep="paso-1" />}
                  />
                </Route>
                <Route
                  element={<CreateFormProvider initialUserType="TEACHER" />}
                >
                  <Route
                    path="crear-usuario/profesor/:paso?"
                    element={<FormPrf defaultStep="paso-1" />}
                  />
                </Route>
                <Route element={<CreateFormProvider initialUserType="ADMIN" />}>
                  <Route
                    path="crear-usuario/admin/:paso?"
                    element={<FormAdm defaultStep="paso-1" />}
                  />
                </Route>
                <Route
                  path="estudiantes"
                  element={
                    <TableStudetns
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
                <Route
                  path="profesores"
                  element={
                    <TableProfessors
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
                <Route
                  path="salones"
                  element={
                    <TableSalones loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />
                <Route
                  path="crear-grupos"
                  element={
                    <TableGroup loggedIn={loggedIn} onLogout={handleLogout} />
                  }
                />
                <Route
                  path="crear-clase-individual"
                  element={
                    <IndividualClassPage
                      loggedIn={loggedIn}
                      onLogout={handleLogout}
                    />
                  }
                />
                <Route element={<CreateIndividualClassProvider />}>
                  <Route
                    path="crear-clase-individual/:paso?"
                    element={<CreateClassPage defaultStep="paso-1" />}
                  />
                </Route>
                <Route
                  path="vender-clase-grupal"
                  element={<SaleGroupClassPage />}
                />
                <Route element={<StudentGroupProvider />}>
                  <Route
                    path="vender-clase-grupal/:paso?"
                    element={<SellGroupForm defaultStep="paso-1" />}
                  />
                </Route>
              </Route>
            )}
          </>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
