import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";

const CreateIndividualContext = createContext();

export const useCreateIndClassContext = () => {
  return useContext(CreateIndividualContext);
};

export const CreateIndividualClassProvider = () => {
  const [formData, setFormData] = useState({
    teacher_id: "",
    instrument_id: "",
    classroom_id: "",
    student_ids: [0],
    pricing_model: "",
    level: 0,
    lessons: [
      {
        start_time: "",
        end_time: "",
        start_date: "",
      },
    ],
    lesson_type: "",
    class_type: "",
    lessons_per_week: "",
    intensity: "",
    volume: "",
    payment_method: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (data) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lesson/level`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log("FormProvider formData:", formData);
      // console.log("Form data submitted:", response.data);
      setSuccess("Clase creada correctamente");

      const responseData = response.data[0];
      const { id, next_payment_value, student_id, payment_count } =
        responseData;
      console.log(id, next_payment_value, student_id, payment_count);

      // const newData = {
      //   data: {
      //     transaction: {
      //       amount_in_cents: next_payment_value,
      //       reference: `pago-para-nivel${id}-estudiante${student_id}-cuota${payment_count}`,
      //       status: "APPROVED",
      //     }
      //   }
      // };

      // const response2 = await axios.post(
      //   `${process.env.REACT_APP_API_URL}/lesson/level/process_balance`,
      //   newData,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //     },
      //   }
      // );
      // console.log('Second POST:',response2.data)
    } catch (error) {
      console.error("Error submitting form data:", error);
      setError(error.response.data.detail);
    }
  };

  return (
    <CreateIndividualContext.Provider
      value={{ formData, setFormData, handleSubmit, error, success }}
    >
      <Outlet />
    </CreateIndividualContext.Provider>
  );
};
