import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import { textFieldStyle, theme } from "../../Styles/FormStyles";
import {
  FormContainer,
  MainWrapper,
  SubmitBtn,
  Title,
  Wrapper,
} from "./OverlayElements";
import { getUserData, updateUser } from "../../../utils/tools";
import dayjs from "dayjs";
import SelectComponent from "../../../ui/Select";
import TextInputField from "../../../ui/TextInputField";

function Overlay({ userId }) {
  const [interactedFields, setInteractedFields] = useState({});
  const [userData, setUserData] = useState({
    id: 0,
    email: "",
    first_name: "",
    last_name: "",
    identification_number_type: "",
    identification_number: "",
    date_of_birth: null,
    phone_number: "",
    user_type: "",
    status: "",
    can_teach_at_home: false,
    emergency_contact_name: "",
    emergency_contact_phone: "",
    billing_mail: "",
    acquisition_channel: "",
    strikes: 0,
    address: "",
    instruments: [
      {
        id: 0,
        created_time: null,
        modified_time: null,
        name: "",
        art_form: "",
        can_teach_at_home: false,
      },
    ],
  });

  const type_doc = [
    {
      label: "Cedula de ciudadania",
      value: "CEDULA",
    },
    {
      label: "Tarjeta de identidad",
      value: "TARJETA DE IDENTIDAD",
    },
    {
      label: "Registro Civil",
      value: "REGISTRO CIVIL",
    },
    {
      label: "Cedula de extranjeria",
      value: "CEDULA DE EXTRANJERIA",
    },
    {
      label: "Pasaporte",
      value: "PASAPORTE",
    },
    {
      label: "Permiso especial",
      value: "PERMISO ESPECIAL",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData(userId);
        setUserData(result);
        console.log("API Response:", result);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, [userId]);

  const hasInteracted = Object.values(interactedFields).some(Boolean);

  const handleSaveChanges = async () => {
    if (hasInteracted) {
      try {
        await updateUser({ userId, userData });
        console.log("Changes saved successfully!");
      } catch (error) {
        console.error("Error saving changes:", error.message);
      }
    } else {
      console.log("No changes to save.");
    }
  };

  const handleChange = (e, fieldName) => {
    setInteractedFields((prevInteractedFields) => ({
      ...prevInteractedFields,
      [fieldName]: true,
    }));

    setUserData((prevUserData) => {
      let updatedUserData = { ...prevUserData };

      if (fieldName === "full_name") {
        const names = e.target.value.split(" ");
        updatedUserData = {
          ...updatedUserData,
          first_name: names[0] || "",
          last_name: names.slice(1).join(" ") || "",
        };
      } else {
        if (e.target) {
          updatedUserData[fieldName] = e.target.value;
        } else {
          updatedUserData[fieldName] = e;
        }
      }

      return updatedUserData;
    });
  };

  return (
    <>
      <Wrapper>
        <MainWrapper>
          <FormContainer>
            <Title style={{ marginBottom: "0rem" }}>Información personal</Title>
            <ThemeProvider theme={theme}>
              <TextField
                id="outlined-basic"
                label="Nombre completo"
                variant="outlined"
                onChange={(e) => handleChange(e, "full_name")}
                value={`${userData.first_name || ""} ${
                  userData.last_name || ""
                }`}
                name="full_name"
                sx={textFieldStyle}
              />

              <div>
                <SelectComponent
                  label="Tipo de documento"
                  menuItems={type_doc}
                  onChange={(e) =>
                    handleChange(e, "identification_number_type")
                  }
                  value={userData.identification_number_type}
                  name="identification_number_type"
                />
              </div>

              <TextField
                id="outlined-basic"
                label="Número de documento"
                variant="outlined"
                onChange={(e) => handleChange(e, "identification_number")}
                value={userData.identification_number}
                name="identification_number"
                sx={textFieldStyle}
              />
              <TextField
                id="outlined-basic"
                label="Correo electrónico"
                variant="outlined"
                onChange={(e) => handleChange(e, "email")}
                value={userData.email}
                name="mail"
                sx={textFieldStyle}
              />
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateField"]}>
                    <DateField
                      label="Fecha de nacimiento"
                      onChange={(e) => handleChange(e, "date_of_birth")}
                      value={dayjs(userData.date_of_birth)}
                      name="date_of_birth"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          width: "327px",
                        },
                        [theme.breakpoints.up("sm")]: {
                          width: "416px",
                        },
                        "& label.Mui-focused": {
                          color: "#215E69",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          "&.Mui-focused fieldset": {
                            borderColor: "#215E69",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <div>
                <TextInputField
                  label="País"
                  onChange={(e) => handleChange(e, "country")}
                  value={userData.country}
                  name="country"
                />
              </div>
              <Title style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                Información de contacto
              </Title>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Dirección"
                  variant="outlined"
                  sx={textFieldStyle}
                  onChange={(e) => handleChange(e, "address")}
                  value={userData.address}
                  name="address"
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={textFieldStyle}
                  label="Número de contacto"
                  onChange={(e) => handleChange(e, "phone_number")}
                  value={userData.phone_number}
                  name="phone_number"
                />
              </div>
              <Title style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                Contacto de emergencia
              </Title>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  marginBottom: "2rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  sx={textFieldStyle}
                  label="Nombre"
                  variant="outlined"
                  onChange={(e) => handleChange(e, "emergency_contact_name")}
                  value={userData.emergency_contact_name}
                  name="emergency_contact_name"
                />
                <TextField
                  id="outlined-basic"
                  label="Número de contacto"
                  variant="outlined"
                  onChange={(e) => handleChange(e, "emergency_contact_phone")}
                  value={userData.emergency_contact_phone}
                  name="emergency_contact_phone"
                  sx={textFieldStyle}
                />
              </div>
              <Title style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                Correo de recepción de estado de cuenta
              </Title>
              <div
                style={{
                  marginBottom: "2rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Correo electrónico"
                  variant="outlined"
                  onChange={(e) => handleChange(e, "billing_mail")}
                  value={userData.billing_mail}
                  name="billing_mail"
                  sx={textFieldStyle}
                />
              </div>
            </ThemeProvider>
          </FormContainer>
        </MainWrapper>
        <SubmitBtn>
          <AdminButtonContainer>
            <AdminFormButton
              onClick={handleSaveChanges}
              disabled={!hasInteracted}
            >
              Guardar cambios
            </AdminFormButton>
          </AdminButtonContainer>
        </SubmitBtn>
      </Wrapper>
    </>
  );
}

export default Overlay;
