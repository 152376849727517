import axios from "axios";
import React, { useEffect, useState } from "react";
import errorImg from "../../../images/Exclamation_circle.svg";
import { fetchClassInfo } from "../../../utils/rescheduleTools";
import { BtnWrapper } from "../../Overlay/OverlayElements";
import { MainWrapper, Wrapper } from "../../admin/Students/OverlayElements";
import Class from "../../CalendarStudents/Class";
import CancelClass from "../../CalendarStudents/OverlayContent/CancelClass";
import CancelWarning from "../../CalendarStudents/OverlayContent/Form/CancelWarning";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";

function CancelletionOfClass({ classData, onClose }) {
  const [step, setStep] = useState(0);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showCancelWithWarrning, setShowCancelWithWarrning] = useState(false);
  const [levelInfo, setLevelInfo] = useState({});
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchClassInfo(classData);
      setLevelInfo(response);
    };
    fetchData();
  }, [classData]);

  console.log("levelInfo", levelInfo);

  const handleCancel = () => {
    if (strikeDisclaimer || timeDifferenceDisclaimer) {
      setShowCancelWithWarrning(true);
    } else {
      setShowConfirmCancel(true);
    }
  };

  const handleConfirmCancellation = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/lesson/${classData.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      setSuccessMessage("Clase Cancelada correctamente");
    } catch (error) {
      console.error("Error:", error);
      setError(error.response.data.detail);
    }
  };

  const handleCancellation = () => {
    if (step === 0) {
      handleConfirmCancellation();
      setStep(1);
    }
  };

  let strikeDisclaimer = null;

  if (levelInfo[0]?.teacher_last_strike && levelInfo[0]?.teacher_strikes >= 2) {
    const currentDate = new Date();
    const lastStrikeDate = new Date(levelInfo[0]?.teacher_last_strike);
    const timeDiff = currentDate.getTime() - lastStrikeDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    console.log("Days difference:", daysDiff);
    console.log("Number of strikes:", levelInfo[0]?.teacher_strikes);

    if (levelInfo[0]?.teacher_strikes >= 2) {
      strikeDisclaimer = (
        <>
          <p
            style={{
              background: "#F9EBEB",
              padding: "0.5rem 1rem",
              borderRadius: " 0.5rem",
              color: "#C23934",
              marginBottom: "1.5rem",
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
          >
            <img src={errorImg} alt="" style={{ marginRight: "0.5rem" }} />
            Tienes más de 2 strikes en menos de 28 días. Si cancelas esta clase
            deberás reponerla y brindar una clase compensatoria.
          </p>
        </>
      );
    }
  }

  let timeDifferenceDisclaimer = null;

  if (classData.start_time) {
    const classStartTime = new Date(classData.start_time);
    const currentDate = new Date();
    const timeDiffInMillis = classStartTime.getTime() - currentDate.getTime();
    const timeDiffInHours = timeDiffInMillis / (1000 * 60 * 60);

    console.log("Time difference in hours:", timeDiffInHours);

    if (timeDiffInHours <= 4.5) {
      timeDifferenceDisclaimer = (
        <>
          <p
            style={{
              background: "#F9EBEB",
              padding: "0.5rem 1rem",
              borderRadius: " 0.5rem",
              color: "#C23934",
              marginBottom: "1.5rem",
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
          >
            <img src={errorImg} alt="" style={{ marginRight: "0.5rem" }} />
            Cancelación de clase fuera de tiempo reglamentario. Si cancelas esta
            clase deberás reponerla y brindar una clase compensatoria.
          </p>
        </>
      );
    }
  }

  console.log("classData", classData);
  return (
    <>
      <Wrapper>
        <MainWrapper>
          {showCancelWithWarrning && (
            <CancelWarning
              onClose={onClose}
              strikeDisclaimer="Tienes más de 2 strikes en el ciclo actual de clases. Si cancelas esta clase deberás reponerla y brindar una clase compensatoria."
              timeDifferenceDisclaimer="Cancelación de clase fuera de tiempo reglamentario. Si cancelas esta clase deberás reponerla y brindar una clase compensatoria."
              step={step}
              error={error}
              successMessage={successMessage}
              onCancel={handleCancellation}
              teacherHeading="Politicas de cancelación"
            />
          )}
          {showConfirmCancel && (
            <CancelClass
              step={step}
              error={error}
              successMessage={successMessage}
              onCancel={handleCancellation}
              onClose={onClose}
              teacherText="Recuerda que sólo podrás cancelar 2 clases durante el ciclo actual del estudiante, luego de eso deberás reponer cada clase cancelada y brindar una clase adicional compensatoria gratuita por la cancelación reiterativa. Si la cancelación se da 4:30 horas antes de la clase programada la sanción se aplicará automáticamente (Re-programación + Compensatoria)"
              teacherHeading="Politicas de cancelación"
            />
          )}

          {!showConfirmCancel && !showCancelWithWarrning && (
            <div style={{ minHeight: "25rem" }}>
              {strikeDisclaimer}
              {timeDifferenceDisclaimer}
              <Class {...classData} />
            </div>
          )}
        </MainWrapper>
        <BtnWrapper
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          {!showConfirmCancel && !showCancelWithWarrning && (
            <>
              <AdminButtonContainer>
                <AdminFormButton onClick={handleCancel}>
                  Cancelar clase
                </AdminFormButton>
              </AdminButtonContainer>
            </>
          )}
        </BtnWrapper>
      </Wrapper>
    </>
  );
}

export default CancelletionOfClass;
