import axios from "axios";
import React, { useEffect, useState } from "react";
import info from "../../../images/InfoBg.svg";
import SelectComponent from "../../../ui/Select";
import {
  AdditionalInfo,
  ClassItem,
  ClassList,
  ClassStarts,
  DateTime,
  Day,
  Duration,
  ItemInfo,
  Time,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import {
  ClassName,
  TimeInfo,
} from "../../MultiStepsForm/individual/StepSix/StepSixElements";
import { Title } from "../Students/OverlayElements";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import {
  fetchAvailableClassrooms,
  fetchAvailableTeachers,
  updateLessonLevel,
} from "../../../utils/apiEditClass";
import FinalMsg from "./FinalMsg";

function EditCourse({ infoData,onRefresh }) {
  const [teachers, setTeachers] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [selectedId, setSelectedId] = useState(infoData.teacher_id);
  const [selectedClassroom, setSelectedClassroom] = useState(infoData.classroom_id);
  const [selectedTime, setSelectedTime] = useState(false);
  const [classInfo, setClassInfo] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  console.log('infoData',infoData)

  const getFormattedTime = (time) => {
    return new Date(time).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const getFormattedDay = (time) => {
    const wDayWithAccents = new Intl.DateTimeFormat("es-CO", {
      weekday: "long",
    }).format(new Date(time));

    return wDayWithAccents
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
  };

  const [initialLessonInfo, setInitialLessonInfo] = useState({
    start_time: infoData.start_time
      ? getFormattedTime(infoData.start_time)
      : "",
    end_time: infoData.end_time ? getFormattedTime(infoData.end_time) : "",
    day: infoData.start_time ? getFormattedDay(infoData.start_time) : "",
  });
  const [selectedLessonInfo, setSelectedLessonInfo] =
    useState(initialLessonInfo);

  console.log(selectedLessonInfo);

  useEffect(() => {
    fetchTeachers();
    fetchClassrooms();
  }, [infoData.instrument_id, infoData.start_time, infoData.end_time]);

  const fetchTeachers = async (withTime=false) => {
    const start = withTime ? null : getFormattedTime(infoData.start_time);
    const end = withTime ? null : getFormattedTime(infoData.end_time);
    const wDay = withTime ? null : getFormattedDay(infoData.start_time);

    try {
      const response = await fetchAvailableTeachers(
        infoData.instrument_id,
        start,
        end,
        wDay
      );
      setTeachers(response);
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };

  const fetchClassrooms = async (withTime=false) => {
    const start = withTime ? null : getFormattedTime(infoData.start_time);
    const end = withTime ? null : getFormattedTime(infoData.end_time);
    const wDay = withTime ? null : getFormattedDay(infoData.start_time);

    try {
      const response = await fetchAvailableClassrooms(
        infoData.instrument_id,
        start,
        end,
        wDay
      );
      setClassrooms(response);
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };

  const handleSubmit = async () => {
    try {
      await updateLessonLevel(
        infoData.level_id,
        selectedId,
        selectedClassroom,
        selectedLessonInfo,
        infoData.id,
      );
      setSuccess("Cambios efectuado correctamente");
      onRefresh()
    } catch (error) {
      setError(error.response.data.detail);
      console.error("Error updating course:", error);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/lesson/availability?instrument_id=${infoData.instrument_id}&lesson_type=${infoData.lesson_type}&teacher_id=${selectedId}&classroom_id=${selectedClassroom}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setClassInfo(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching class information from API:", error);
      });
  }, [
    infoData.instrument_id,
    infoData.lesson_type,
    selectedId,
    selectedClassroom,
  ]);

  const inputDate = new Date(infoData?.start_time);
  const options = { weekday: "long" };
  const dayOfWeek = capitalizeFirstLetter(
    new Intl.DateTimeFormat("es-CO", options).format(inputDate)
  );
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const time = inputDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  function getNextDateWithDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    console.log("targetDayIndex", targetDayIndex);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }

  const handleCardClick = (index, classItem) => {
    let updatedSelectedCards = [...selectedCards];
    updatedSelectedCards = [index];
    setSelectedCards(updatedSelectedCards);
    localStorage.setItem("selectedCards", JSON.stringify(updatedSelectedCards));

    const updatedLessons = updatedSelectedCards.map((selectedIndex) => {
      const selectedClassItem = classInfo[selectedIndex];
      const localizedStartDate = getNextDateWithDay(selectedClassItem.day);
      const year = localizedStartDate.getFullYear();
      const month = ("0" + (localizedStartDate.getMonth() + 1)).slice(-2);
      const day = ("0" + localizedStartDate.getDate()).slice(-2);
      const formattedStartDate = `${year}-${month}-${day}`;

      setSelectedLessonInfo({
        day: selectedClassItem.day,
        start_time: selectedClassItem.start_time_,
        end_time: selectedClassItem.end_time_,
      });
    });
  };

  const teacherItems = [
    ...teachers.map((teacher) => ({
      label: `${teacher.first_name} ${teacher.last_name}`,
      value: teacher.id,
    })),
    {
      label: infoData.teacher_name,
      value: infoData.teacher_id,
    },
  ];

  const ClassromItems = [
    ...classrooms.map((classroom) => ({
      label: classroom.name,
      value: classroom.id,
    })),
    {
      label: infoData.classroom_name,
      value: infoData.classroom_id,
    },
  ];

  console.log("level_id", infoData.level_id);

  
  const handleTimeChange = (value) => {
    setSelectedTime(value);
    fetchTeachers(value);
    fetchClassrooms(value);
    if (!value) {
      setSelectedLessonInfo(initialLessonInfo);
      setSelectedCards([]);
    }
  };

  return (
    <>
      {!success && !error ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "auto",
            height: "85vh",
          }}
        >
          <div style={{ marginBottom: "1.5rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={info} alt="" />
              <Title style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}>
                Editar curso
              </Title>
              <p style={{ color: "#757575" }}>
                Revisa los datos para cambiar los parámetros de esta clase
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "29rem",
                  padding: "0rem 1.5rem",
                  marginTop: "2rem",
                }}
              >
                <h2 style={{ marginBottom: "0.5rem", fontWeight: 500 }}>
                  Información actual
                </h2>
                <ClassName>
                  Clases de{" "}
                  {infoData.instrument_name
                    ? infoData.instrument_name.charAt(0).toUpperCase() +
                      infoData.instrument_name.slice(1).toLowerCase()
                    : ""}
                </ClassName>
                <TimeInfo style={{ marginBottom: "0rem" }}>
                  <Day>Intensidad {infoData.intensity}</Day>
                  <Day>
                    {infoData.is_groupal === true
                      ? "Clases Grupales"
                      : "Clases Personalizada"}
                  </Day>
                  <Day>
                    {dayOfWeek} de cada semana - {time}
                  </Day>
                </TimeInfo>
                {/* <p
                  style={{
                    color: "#6E43E7",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                  }}
                >
                  Clases restantes {infoData.remaining_lessons}
                </p> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: "1.5rem" }}>
                <h2 style={{ marginBottom: "0.5rem", fontWeight: 500 }}>
                  Cambio de horario
                </h2>
                <SelectComponent
                  label="Cambiar"
                  menuItems={[
                    { label: "Sí", value: true },
                    { label: "No", value: false },
                  ]}
                  value={selectedTime}
                  // onChange={(e) => setSelectedTime(e.target.value)}
                  onChange={(e) => handleTimeChange(e.target.value)}
                />
                <h2
                  style={{
                    marginBottom: "0.5rem",
                    fontWeight: 500,
                    marginTop: "1.5rem",
                  }}
                >
                  Cambio de profesor
                </h2>
                <SelectComponent
                  label="Profesor"
                  menuItems={teacherItems}
                  value={selectedId}
                  onChange={(e) => setSelectedId(e.target.value)}
                />
                <h2
                  style={{
                    marginBottom: "0.5rem",
                    fontWeight: 500,
                    marginTop: "1.5rem",
                  }}
                >
                  Cambio de salón
                </h2>
                <SelectComponent
                  label="Salón"
                  menuItems={ClassromItems}
                  value={selectedClassroom}
                  onChange={(e) => setSelectedClassroom(e.target.value)}
                />
              </div>
            </div>
          </div>

          {selectedTime === true && (
            <div>
              <ClassList
                style={{
                  width: "26rem",
                  paddingTop: "0.2rem",
                  overflow: "auto",
                  maxHeight: "30vh",
                }}
              >
                {classInfo.map((classItem, index) => (
                  <ClassItem
                    key={index}
                    isSelected={selectedCards.includes(index)}
                    onClick={() => handleCardClick(index, classItem)}
                  >
                    <ItemInfo>
                      <Time>
                        {classItem.start_time_.substring(0, 5)}
                        <Duration> (1 hora de duración)</Duration>
                      </Time>
                      <Day>
                        {classItem.day.charAt(0).toUpperCase() +
                          classItem.day.slice(1).toLowerCase()}{" "}
                        de cada semana
                      </Day>
                      <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                      <ClassStarts>
                        Comienzo de clases<br></br>
                        <DateTime>
                          {getNextDateWithDay(classItem.day).toLocaleDateString(
                            "es-ES",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                        </DateTime>
                      </ClassStarts>
                    </ItemInfo>
                  </ClassItem>
                ))}
              </ClassList>
            </div>
          )}
          <div style={{ marginTop: "1rem" }}>
            <AdminButtonContainer>
              <AdminFormButton onClick={handleSubmit}>
                Confirmar
              </AdminFormButton>
            </AdminButtonContainer>
          </div>
        </div>
      ) : (
        <FinalMsg error={error} success={success} />
      )}
    </>
  );
}

export default EditCourse;
