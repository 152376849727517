import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider } from "@emotion/react";
import {
  TableHeadStyle,
  searchInput,
  theme,
  themeTable,
} from "../../Styles/FormStyles";
import { EditBtnWrapper } from "./StudentsTablesElements";
import axios from "axios";
import Modal from "../../../ui/Modal";
import Overlay from "./Overlay";
import ActiveMenu from "../../../ui/ActiveMenu";
import DeleteScreen from "../DeleteScreen";
import WatchStudent from "./WatchStudent";
import { TextField } from "@mui/material";

function TableMui() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWatch, setIsOpenWatch] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");

  const columns = [
    {
      id: "id",
      label: "ID",
      align: "left",
    },
    {
      id: "identification_number",
      label: "Número de documento",
      align: "left",
    },
    {
      id: "name",
      label: "Estudiantes",
      align: "left",
      format: (value, row) => (
        <p key={row.id}>
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      id: "courses_avail",
      label: "Cursos disponibles",
      align: "left",
    },
    {
      id: "strikes",
      label: "Strikes",
      align: "left",
      minWidth: 100,
    },
    {
      id: "modal",
      label: "Acciones",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      format: (value, row) => (
        <EditBtnWrapper>
          <ActiveMenu
            key={row.id}
            onClickEdit={() => handleEditClick(row.id)}
            onClickDelete={() => handleDeleteClick(row.id)}
            onClickWatch={() => handleWatchClick(row.id)}
            showAdditionalMenuItem={true}
          />
        </EditBtnWrapper>
      ),
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleWatchClick = (userId) => {
    setSelectedUserId(userId);
    setIsOpenWatch(true);
  };

  const handleEditClick = (userId) => {
    setSelectedUserId(userId);
    setIsOpenModal(true);
  };

  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCloseWatchModal = () => {
    setIsOpenWatch(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/?user_type=STUDENT&status=ACTIVE`,
          {
            params: {
              limit: rowsPerPage,
              page: page + 1,
              keyword: search,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const resultData = response.data;
        console.log(resultData);
        setData(resultData);
        const total = parseInt(response.headers["pagination-total-size"]);
        console.log("Total", total);
        setTotal(total);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [page, rowsPerPage, search]);

  const filteredData = data.filter((item) => {
    return (
      search.trim() === "" ||
      item.first_name.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <>
      <div style={{ marginBottom: "1.5rem", paddingTop: "0.5rem" }}>
        <ThemeProvider theme={theme}>
          <TextField
            sx={searchInput}
            type="text"
            label="Buscador"
            placeholder="Ingresa el nombre del estudiante"
            onChange={(e) => setSearch(e.target.value)}
          />
        </ThemeProvider>
      </div>
      <ThemeProvider theme={themeTable}>
        <Paper sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
          <TableContainer
            sx={{ maxHeight: "60vh", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={TableHeadStyle}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.headerAlign || column.align || "left",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {data.filter((data) => {
                  return search.toLowerCase() === '' ? data : data.first_name.toLowerCase().includes(search)
                }).map((data) => { */}
                {filteredData.map((data) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      {columns &&
                        columns.map((column) => {
                          const value = data[column.id];
                          return (
                            <TableCell
                              key={`${data.id}-${column.id}`}
                              align={column.align}
                            >
                              {column.format
                                ? column.format(value, data)
                                : value}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </ThemeProvider>
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)} props="Detalle estudiante">
          <Overlay userId={selectedUserId} />
        </Modal>
      )}
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <DeleteScreen userId={selectedUserId} onClose={handleCloseModal} />
        </Modal>
      )}
      {isOpenWatch && (
        <Modal onClose={() => setIsOpenWatch(false)} props="Ver estudiante">
          <WatchStudent
            userId={selectedUserId}
            onClose={handleCloseModal}
            onCloseWatch={handleCloseWatchModal}
          />
        </Modal>
      )}
    </>
  );
}

export default TableMui;
